import { axios, API_ROUTES } from "@/config";

export default {
  /**
   * Consult the category icons by id
   * @param {string} params - Gets the hash SHA-256.
   * @param {string} id - Gets id by category.
   */
  async getAll(id, params) {
    let result = await axios.get(`${API_ROUTES.category.get}/${id}`, {
      params: params,
    });

    return result;
  },
  /**
   * Consult the investements icons
   * @param {string} params - Gets the hash SHA-256.
   */
  async getAllInvestments(params) {
    let result = await axios.get(`${API_ROUTES.investment.get}`, {
      params: params,
    });

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.lead.save}`, data);

    return result;
  },
};
